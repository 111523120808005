.App {
  width: 100vw;
  height: 100vh;
  /* padding: 20px;
  min-height: 100vh;
  background-color: #282c34;
  color: white; */

}

body{
  margin: 0;
  padding: 0;
}

.App-header {
  text-align: left;
  font-size: calc(10px + 2vmin);
}

.homeContainer {
  max-height: 500px;
  overflow-y:scroll;
 
}

.containers{
  padding: 0px;
  margin: 0px

}

.loginContainer{
  width: 33.33%;
  margin: 5% 33.3%;
}

.tables{
  padding: 0px;
  margin: 5px;
  font-size:13px;
}

.buttons{
  padding: 4px;
  margin: 1px

}

.layout{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

}

.brand{
  padding: 4px;
  /* margin-left: 20px; */

  

}

.togglebutton{
  margin-left: 120px

}

/* .sidebar{
  height: 100%;
  width: 250px;
  background-color: gray;
} */

/* .sidebarlist{
  height: auto;
  width: 100%;
}

.sidebarlist .row{
  width: 100%;
  height: 50px;
  list-style-type: none;
  margin: 0px;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

}

.sidebarlist .row:hover{
  cursor: pointer;
  background-color: turquoise;
}
 */


 .sidebar {
  height: 100%;
  min-height: 100vh !important;
  width: 0;
  position: fixed;
  z-index: 100;
  top: 0;
  left: -100%;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;

  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  background-color: #111; */

}

.sidebar.active{
  left: 0;
}

.sidebar a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #111;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}





.sidebar-container{
  background-color: #444;
  height: 100vh;
  width: 0%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s;
  margin-top: 55px;
  overflow-anchor: none;
  border-right: dimgray;

}

.sidebar-container.active{
  left: 0;
  width: 15%;
  transition: 0.5s;

}


.sidebar-item{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  width: 100%;
  list-style: none;

}

.sidebar-item .active1{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  list-style: none;

}

.sidebar-item a{
  text-decoration: none;
  color: azure;
  font-size: 1rem;
  height: 100%;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding:0,1.5rem;
  border-radius: 4px;
}

.sidebar-item a:hover{
  background-color: azure;
  color: #111;

}

.sidebar-items{
  width: 100%;
}

.sidebar-icon{
  padding-right: 1rem;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  justify-content: flex-start;
}

.main.active {
  transition: .5s;
  padding: 0px;
  margin-left: 7%;
  background-color: #ffffff;
  display: flex;
  z-index: -1;
  margin-top: 40px;
}

.main {
  transition: 0.5s;
  padding: 0px;
  margin-left: 15%;
  background-color: #ffffff;
  display: flex;
  z-index: -1;
  margin-top: 40px;
}

.main-container{
  display: flex;
  
}


@media (max-width: 992px) {

  .main.active {
    padding: 0px;
    margin-left: 25%;
    margin-top: 40px;

  }
  
  .main {
    padding: 0px;
    margin-left: 25%;
    margin-top: 40px;
  }

}


/* .pagination-title{
  color: red;
}

.pagination{
  background-color: aqua;
} */


