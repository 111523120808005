.topbar{
    width: 100%;
    height:50px;
    background-color: crimson;
    position: sticky;
    top: 0;
    
}

.topbarWrapper{
    height: 100%;
    padding:0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo{
    font-weight: bold;
    font-size: 30px;
    color: white;
}

.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer{
    position: relative;
    cursor: pointer;
    color: white;
    margin-right: 10px;
}

.topIconBagdge{
    width: 15px;
    height:15px;
    position: absolute;
    top:-5px;
    right:0px;
    background-color:white;
    color: darkblue;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;

}


.sidenav{
    flex: 1;
    background-color: cornflowerblue;
    height:calc(100vh-50px);
    position: sticky;
    top:50px;
}

.sidenavWrapper{
    padding: 20px;
    color: cornsilk;
}

.sidenav-menu{
    margin-bottom: 10px;
}

.sidenav-list{
    list-style: none;
    padding: 5px;
}

.sidenav-item{
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.sidenav-item:hover{
    background-color: rgb(53, 117, 235);
    
}

.sidenavIcon{
    margin-right: 10px;
    align-items: center;
    font-size: 20px !important;
}

.home{
    flex: 8;
    background-color: white;
  }

  .homeCard{
      width:80%;
      height:100%;
      align-items: center;
  }

  .homeRow{
      margin-left: 50px;
      margin-top: 20px;
  }

  .chart{
      margin:20px;
      padding: 20px;
      -webkit-box-shadow: 0px 0px,15px,-10px rgb(172, 16, 16);
      box-shadow: 0px 0px,15px,-10px  rgb(170, 31, 31);
  }

